<template>
  <v-snackbar
      :timeout="timeout"
      :color="color"
      v-bind:modelValue="open"
      @update:model-value="(value: boolean) => emit('update:open', value)"
  >
    {{ text }}
  </v-snackbar>
</template>

<script setup lang="ts">
withDefaults(
    defineProps<{
      open: boolean
      color: string
      timeout?: number
      text: string
    }>(),
    { timeout: 5000 }
)
const emit = defineEmits(['update:open'])


</script>
