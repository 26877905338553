<template>
  <v-footer class="bg-primary pa-0">
    <v-container fluid class="pa-0">
      <v-row class="ma-10 justify-center">
        <v-col :cols="$vuetify.display.xs ? 12 : 6">
          <h6 id="footer-nyttige-nettsider-label" :class="{ 'justify-center': $vuetify.display.xs }" class="font-weight-medium justify-center text-wrap text-h5 mt-5">
            {{ t('footer.contact.title') }}
          </h6>
          <v-card-actions class="justify-center mt-3">
            <v-row>
              <v-col
                :class="{ 'justify-center': $vuetify.display.xs }"
                cols="12"
              >
                <v-btn
                  elevation="0"
                  color="white"
                  class="link-button"
                  variant="text"
                  href="/kontakt"
                  >{{ t('footer_sm.contact.link_laerlingtorget') }}
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
            <h6 id="footer-nyttige-nettsider-label" :class="{ 'justify-center': $vuetify.display.xs }" class="font-weight-medium justify-center break-word text-h5 mt-5">
              {{ t('footer.web_info.source.heading') }}
            </h6>
          <p class="text-white justify-center mt-3">
            {{t('footer.web_info.source.text')}}
          </p>
        </v-col>
        <v-col :cols="$vuetify.display.xs ? 12 : 6" :class="$vuetify.display.xs ? 'text-center' : 'text-right'">
          <v-row justify="center">
            <v-col cols="12" align-self="center">
              <h5 id="footer-nyttige-nettsider-label" class="font-weight-medium justify-center break-word text-h5 mt-5">
                {{ t('footer.useful_links.title') }}
              </h5>
            </v-col>
          </v-row>
          <v-row justify="center" :class="$vuetify.display.xs ? 'text-center' : 'text-right'">
            <v-col cols="12">
              <v-btn
                  href="https://www.udir.no/"
                  role="link"
                  elevation="0"
                  width="200"
                  color="white"
                  class="link-button"
                  rounded
                  variant="outlined"
                  target="_blank"
                  append-icon="mdi-open-in-new"
                  id="udir"
                  aria-labelledby="footer-nyttige-nettsider-label udir"
              >{{ t('footer.useful_links.link1') }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                  href="https://www.vilbli.no/"
                  role="link"
                  elevation="0"
                  width="200"
                  color="white"
                  class="link-button"
                  rounded
                  variant="outlined"
                  target="_blank"
                  append-icon="mdi-open-in-new"
                  id="vilbli"
                  aria-labelledby="footer-nyttige-nettsider-label vilbli"
              >{{ t('footer.useful_links.link2') }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                  href="https://www.vigoiks.no/"
                  role="link"
                  elevation="0"
                  width="200"
                  color="white"
                  class="link-button"
                  rounded
                  variant="outlined"
                  target="_blank"
                  append-icon="mdi-open-in-new"
                  id="vigoiks"
                  aria-labelledby="footer-nyttige-nettsider-label vigoiks"
              >{{ t('footer.useful_links.link3') }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                  href="https://www.lanekassen.no/ "
                  role="link"
                  elevation="0"
                  width="200"
                  color="white"
                  class="link-button"
                  rounded
                  variant="outlined"
                  target="_blank"
                  append-icon="mdi-open-in-new"
                  id="lanekassen"
                  aria-labelledby="footer-nyttige-nettsider-label lanekassen"
              >{{ t('footer.useful_links.link4') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="ma-0 pa-0 pt-5">
        <v-col cols="12" class="ma-0 pa-0">
          <footer-web-info />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import FooterWebInfo from '@/components/FooterWebInfo.vue'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.web-info {
  background-color: #003440;
}
.link-button {
  text-transform: none;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
