import { createRouter, createWebHistory } from 'vue-router'

const LandingPage = () => import('@/views/landingPage/LandingPage.vue')
const Sokere = () => import('@/views/sokere/Sokere.vue')
const Contact = () => import('@/views/contact/Contact.vue')
const Bedrifter = () => import('@/views/bedrifter/Bedrifter.vue')

const LaerebedriftOversikt = () => import('@/views/oversikter/LaerebedriftOversikt.vue')
const SokerOversikt = () => import('@/views/oversikter/SokerOversikt.vue')

const MedlemsbedriftTabell = () => import('@/views/oversikter/MedlemsbedriftTabell.vue')

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: LandingPage
  },
  {
    path: '/sokere',
    name: 'sokere',
    component: Sokere
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact
  },
  {
    path: '/bedrifter',
    name: 'bedrifter',
    component: Bedrifter
  },
  {
    path: '/oversikt-laerebedrifter',
    name: 'oversikt-laerebedrifter',
    component: LaerebedriftOversikt
  },
  {
    path: '/oversikt-laerebedrifter/medlemsbedrifter/',
    name: 'oversikt-medlemsbedrifter',
    props: true,
    component: MedlemsbedriftTabell
  },
  {
    path: '/oversikt-sokere',
    name: 'oversikt-sokere',
    component: SokerOversikt
  }
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, left: 0 }
  }
})

export default router
