<template>
  <v-list v-model:opened="open">
    <v-list-item append-icon="mdi-home" title="Forsiden" @click="router.push({ name: 'home' })"></v-list-item>
      <v-list-group value="menu.sokere">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="t('menu.sokere.title')"
          ></v-list-item>
        </template>

        <v-list-item
          v-for="(menuItem, i) in sokere"
          :key="i"
          :title="menuItem.text"
          :value="menuItem.text"
          :class="menuItem.cssClasses"
          @click="navigateTo(menuItem)"
        >
          <template v-slot:append>
            <v-icon size="x-small" :icon="menuItem.external ?  'mdi-open-in-new' : 'mdi-arrow-right'" />
          </template>
        </v-list-item>
      </v-list-group>

      <v-list-group value="menu.bedrifter">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="t('menu.bedrifter.title')"
          ></v-list-item>
        </template>

        <v-list-item
          v-for="(menuItem, i) in bedrifter"
          :key="i"
          :title="menuItem.text"
          :value="menuItem.text"
          :class="menuItem.cssClasses"
          @click="navigateTo(menuItem)"
        >
          <template v-slot:append>
            <v-icon size="x-small" :icon="menuItem.external ?  'mdi-open-in-new' : 'mdi-arrow-right'" />
          </template>
        </v-list-item>
      </v-list-group>
    <v-list-item :title="t('menu.contact.kontaktside')"
                 @click="router.push({ name: 'contact' })" class="light-gray-background text-primary text-h6" />
  </v-list>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { isRedundantNavigation } from '@/helpers'
import router from '@/router'
import { VigoMenuItem } from '@/state/types'

const { t } = useI18n()
const route = useRoute()

const open = ref(['menu.sokere', 'menu.bedrifter'])
const sokere: VigoMenuItem[] = ref([
  { text: t('menu.sokere.laereplass'), route: 'sokere', external: false, cssClasses: 'light-orange-background text-primary text-h6' },
  { text: t('menu.sokere.finn-bedrift'), route: 'oversikt-laerebedrifter', external: false, cssClasses: 'light-orange-background text-primary text-h6' },
  { text: t('menu.sokere.vigo-soknad'), route: 'https://www.vigo.no/nyvigo/vigo/youth', external: true, cssClasses: 'text-primary text-h6' }
])
const bedrifter: VigoMenuItem[] = ref([
  { text: t('menu.bedrifter.bli-laerebedrift'), route: 'https://www.vilbli.no/nb/no/a/fagopplaeringskontorene-6', external: true, cssClasses: 'light-green-background text-primary text-h6' },
  { text: t('menu.bedrifter.oversikt-sokere'), route: 'oversikt-sokere', external: false, cssClasses: 'light-green-background text-primary text-h6' },
  { text: t('menu.bedrifter.se-laereplaner'), route: 'https://www.vilbli.no/nb/nb/no/laereplanverket-for-kunnskapsloftet/a/032903', external: true, cssClasses: 'text-primary text-h6' },
  { text: t('menu.bedrifter.vigo-bedrift'), route: 'https://www.vigobedrift.no', external: true, cssClasses: 'text-primary text-h6' }
])

function navigateTo(menuItem: MenuItemType): void {
  if (menuItem.external) {
    window.open(menuItem.route, '_blank')
    return
  }
  if (!isRedundantNavigation(route.name, menuItem.route)) {
    router.push({ name: menuItem.route })
  }
}
</script>
<style scoped lang="scss">
.light-green-background {
  background-color: $light-green;
}
.light-gray-background {
  background-color: $light-purple;
}
.light-orange-background {
  background-color: $light-orange;
}

</style>
