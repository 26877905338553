import { createVuetify, type ThemeDefinition } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import DateFnsAdapter from '@date-io/date-fns'
import nbNO from 'date-fns/locale/nb'
import nnNO from 'date-fns/locale/nn'
import 'vuetify/styles'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'
import { i18n } from '@/i18n'

const vigoTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#1e4e5a',
    secondary: '#efeaea',
    accent: '#c2f4a7',
    accentLightGreen: '#ebffe0',
    accentLightOrange: '#ffe0cc',
    backgroundColor: '#FAFAFA'
  }
}

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'vigoTheme',
    themes: {
      vigoTheme
    }
  },
  date: {
    adapter: DateFnsAdapter,
    locale: {
      no: nbNO,
      nn: nnNO,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n })
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
})
