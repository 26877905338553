<template>
  <v-app>
    <v-app-bar color="white" :elevation="$vuetify.display.xs ? '2' : '0'">
      <v-img
          contain
          alt="VIGO Logo"
          :src="vigoLogoUrl"
          @click="$router.push({ name: 'Main' })"
          class="ma-1 pl-4 cursor-pointer"
          max-width="60"
          max-height="30"
      ></v-img>
      <LanguageSelector />
      <v-spacer />
      <v-app-bar-nav-icon variant="text" @click.stop="menuOpen = !menuOpen"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
        v-model="menuOpen"
        location="right"
        temporary
        width="350"
    >
      <Menu v-if="menuOpen" />
    </v-navigation-drawer>

    <v-main class="ma-0 pb-0">
      <router-view ref="routerRef" />
    </v-main>
    <v-bottom-navigation style="height: 0">
      <Snackbar
          :color="snackbarColor"
          :text="snackbarText"
          :open="showSnackbar"
          v-on:update:open="showSnackbar = false"
        />
    </v-bottom-navigation>
    <Footer ref="footer" />
  </v-app>
</template>

<script setup lang="ts">
import Snackbar from './components/Snackbar.vue'
import Footer from './components/Footer.vue'
import { ref } from 'vue'
import vigoLogoUrl from '@/assets/logo.svg'
import Menu from '@/components/Menu.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import {useI18n} from 'vue-i18n'
const { t } = useI18n()


const menuOpen = ref(false)

  const snackbarColor = ref('error')
  const snackbarText = ref('')

  const showSnackbar = ref(false)
/*
  async created() {
    try {
      eventBus.$on('showSuccess', (success: string) =>
        this.showSuccess(success)
      )
      eventBus.$on('showError', (error: string) => this.showError(error))
    } catch (e) {
      this.showError('Failed to initialise app')
    }
  }

  // Methods
  private showError(error: string) {
    this.snackbarColor = 'error'
    this.openSnackbar(error)
  }

  private showSuccess(success: string) {
    this.snackbarColor = 'success'
    this.openSnackbar(success)
  }

  private openSnackbar(text: string) {
    if (this.showSnackbar) {
      this.showSnackbar = false
    }
    this.showSnackbar = true
    this.snackbarText = text
  }*/

</script>
<style lang="scss">
.wrapper {
  overflow-y: visible !important;
  background-color: white;
}

.max-width {
}
.contact-button {
  background-color: #f3faef;
}
</style>
