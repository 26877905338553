import { createI18n, type I18n } from 'vue-i18n'

import nn from './locales/nn.json'
import no from './locales/no.json'
import { ref } from 'vue'

const messages = {
  nn,
  no
}

const options = {
  legacy: false,
  locale: import.meta.env.VUE_APP_I18N_LOCALE || 'no',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'no',
  messages: messages
}

const i18n: I18n<any, {}, {}, string, false> = createI18n(options)
const globalInstance = i18n.global


const globalLocale = ref(options.locale)

function setGlobalLocale(locale: string) {
  const idx = globalInstance.availableLocales.findIndex( obj => obj == locale)
  if (idx >= 0) {
    globalInstance.locale.value = globalInstance.availableLocales[idx]
    globalLocale.value = globalInstance.availableLocales[idx]
  }
}

function getGlobalLocale() {
  return globalLocale.value
}

export { i18n, setGlobalLocale, getGlobalLocale }

export default globalInstance
