<template>
  <v-row class="web-info pa-0 ma-0">
    <v-col
      :class="
        $vuetify.display.lgAndUp
          ? 'text-center secondary--text pa-1'
          : 'text-center secondary--text'
      "
      cols="12"
    >
      <i18n-t tag="span" keypath="footer.web_info.host" scope="global">
        <template v-slot:link>
          <a
            href="https://www.ist.com/no/"
            target="_blank"
            style="font-weight: bold; color: white; text-decoration: underline; cursor: pointer"
            >IST</a
          >
        </template>
      </i18n-t>
    </v-col>
    <v-col
      :class="
        $vuetify.display.lgAndUp
          ? 'text-center secondary--text pa-1'
          : 'text-center secondary--text'
      "
      cols="12"
    >
      <i18n-t tag="span" keypath="footer.web_info.header" scope="global">
        <template v-slot:link>
          <a
            href="https://vigo.no/vigo/html/img/PersonvernerklaeringVIGO_NO.pdf"
            target="_blank"
            style="font-weight: bold; color: white; text-decoration: underline; cursor: pointer"
          >
            {{ t('footer.web_info.gdpr') }}
          </a>
          og
          <a
            href="https://uustatus.no/nb/erklaringer/publisert/564c842c-1509-4731-8a71-d7a647c55d15"
            target="_blank"
            style="font-weight: bold; color: white; text-decoration: underline; cursor: pointer"
          >
            {{ t('footer.web_info.availability') }} </a
          >.
          <a
            style="font-weight: bold; color: white; text-decoration: underline; cursor: pointer"
            href="mailto:post@vigoiks.no?subject=Tilbakemelding"
          >
            {{ t('footer.web_info.feedback') }}
          </a>
        </template>
      </i18n-t>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>
