<template>
  <div v-if="!$vuetify.display.xs">
    <v-btn
      class="ml-5 mt-1 language-btn text-decoration-underline"
      elevation="0"
      variant="text"
      color="primary"
      @click="setLocale"
      >{{ t('app_bar.translation_button') }}</v-btn
    >
  </div>
  <div v-else>
    <v-btn
      class="ml-5 mt-1 language-btn"
      elevation="0"
      variant="text"
      color="primary"
      @click="setLocale"
    >
      <v-icon left>mdi-web</v-icon></v-btn
    >
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { setGlobalLocale, getGlobalLocale } from '@/i18n'

const { t } = useI18n()

function setLocale() {
  if (getGlobalLocale() === 'no') {
    setGlobalLocale('nn')
  } else setGlobalLocale('no')
}

</script>
<style scoped lang="scss">
.language-btn {
  text-transform: none;
}
</style>
